import React, { ChangeEvent } from "react"

type InputProps = {
  label: string
  value: string
  onInputValueChanged: (value: string) => void
  error?: string
  stretch?: boolean
  type?: string
}

const Input = (props: InputProps) => {
  const type = props.type ? props.type : "text"
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onInputValueChanged(e.target.value)
  }
  const widthClasses = props.stretch ? "w-full" : "w-full md:w-48 lg:w-60 xl:w-64"
  return (
    <div>
      <div className="text-sm text-gray-700">{props.label}</div>
      <label
        className={`flex flex-row justify-between align-middle px-3 border-gray-600 bg-gray-100 hover:bg-gray-200 focus-within:border-gray-600 border-2 overflow-hidden ${widthClasses}`}
        htmlFor={props.label}
      >
        <input
          className="w-full outline-none py-1 bg-gray-100 hover:bg-gray-200"
          id={props.label}
          type={type}
          value={props.value}
          onChange={handleChange}
        ></input>
      </label>
      {props.error ? <div className="text-xs italic text-red-500">{props.error}</div> : <></>}
    </div>
  )
}

export default Input
