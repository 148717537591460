import React, { useCallback, useState } from "react"

import Layout from "../components/layout"
import Head from "../components/header/head"
import ContactCard from "../components/contact-card"
import Modal from "../components/modal/modal"
import ContactForm from "../components/contact-form"

const ContactPage = () => {
  const [displayContactForm, setDisplayingContactForm] = useState<boolean>(false)
  const handleOpenContactForm = useCallback(() => {
    setDisplayingContactForm(true)
  }, [displayContactForm])
  const handleCloseContactForm = useCallback(() => {
    setDisplayingContactForm(false)
  }, [displayContactForm])
  return (
    <Layout headerColor="" headerPageName="Contact" headerSubTitle="" headerTitle="">
      <Head title="Contact" description="Contact" />
      {displayContactForm ? (
        <Modal onClose={handleCloseContactForm}>
          <ContactForm></ContactForm>
        </Modal>
      ) : (
        <></>
      )}
      {/* <h2>laVoieDesMaitres@contact.com</h2> */}
      <div className="mb-8 text-xl text-gray-900 font-semibold">Contact:</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 justify-items-center">
        <ContactCard
          image="formulaire-contact"
          title="Posez votre question ou partagez votre remarque générale"
          moreInfo="La Voie des Maîtres n’est pas habilité à répondre aux questions liées aux traductions, sauf si vous y relevez des erreurs."
          link={{ text: "Soumettre votre requête", to: "" }}
          onContactCardClick={handleOpenContactForm}
        ></ContactCard>
        <ContactCard
          image="voyage"
          title="Rendrez visite à Shaykh Muhammad Adil"
          moreInfo="Le protocole des bonnes convenances ou l’adab du visiteur, à Akbaba, à Lefke"
          link={{ text: "Comment s’y rendre ?", to: "" }}
        ></ContactCard>
        <ContactCard
          image="entraide"
          title="Devenir acteur de la Voie Muhammadienne"
          moreInfo="Lisez au préalable la fiche de poste et les compétences requises"
          link={{
            text: "Rejoignez l’équipe des bénévoles",
            to: "/posts/la-charte-de-traduction-de-redaction-et-d-edition",
          }}
        ></ContactCard>
        <ContactCard
          image="fleur"
          title="Recueillement spirituels auprès des Maîtres de la Voie"
          moreInfo="Le protocole des bonnes convenances ou l’adab du disciple"
          link={{
            text: "Consultez les lieux d’accueil et de visites spirituelles",
            to: "",
          }}
        ></ContactCard>
      </div>
    </Layout>
  )
}
export default ContactPage
