import React, { MouseEvent, ReactNode, useEffect } from "react"

type ModalProps = {
  children: ReactNode
  onClose?: () => void
}

const Modal = (props: ModalProps) => {
  useEffect(() => {
    if (document) {
      document.body.style.overflow = "hidden"
    }
  })
  const handleClose = (e: MouseEvent<HTMLElement>) => {
    if (props.onClose) {
      if (document) {
        document.body.style.overflow = "auto"
      }
      props.onClose()
    }
  }
  return (
    <div className="z-50 absolute inset-0 bg-white w-screen h-screen bg-opacity-80 flex flex-row justify-items-center">
      <div className="bg-white m-auto h-full w-full sm:h-160 sm:w-160 shadow-xl bg-opacity-100 relative px-8 py-16">
        <button
          className="outline-none h-12 w-12 focus:outline-none absolute top-4 right-4 hover:bg-gray-100 rounded-full"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 m-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {props.children}
      </div>
    </div>
  )
}

export default Modal
