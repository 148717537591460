import React, { useCallback, useState } from "react"
import Button from "./form/button"
import Input from "./form/input"
import TextArea from "./form/textarea"

type ContactFormProps = {}

const ContactForm = (props: ContactFormProps) => {
  const [prenom, setPrenom] = useState<string>("")
  const [prenomErr, setPrenomErr] = useState<string>("")
  const [nom, setNom] = useState<string>("")
  const [nomErr, setNomErr] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [emailErr, setEmailErr] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [messageErr, setMessageErr] = useState<string>("")
  const [hasErr, setHasErr] = useState<boolean>(false)

  const handlePrenomChanged = useCallback((value: string) => {
    setPrenom(value)
  }, [])
  const handleNomChanged = useCallback((value: string) => {
    setNom(value)
  }, [])
  const handleEmailChanged = useCallback((value: string) => {
    setEmail(value)
  }, [])
  const handleMessageChanged = useCallback((value: string) => {
    setMessage(value)
  }, [])
  const handleSubmit = (e: any) => {
    e.preventDefault()
    let hasErr = false
    const regName = /^[a-zA-Z]+ [a-zA-Z]+$/
    const regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    if (!prenom) {
      setPrenomErr("Ce champ est obligatoire.")
      hasErr = true
    } else if (!regName.test(prenom)) {
      setPrenomErr("Prénom non valide.")
      hasErr = true
    }
    if (!nom) {
      setNomErr("Ce champ est obligatoire.")
      hasErr = true
    } else if (!regName.test(nom)) {
      setNomErr("Prénom non valide.")
      hasErr = true
    }
    if (!email) {
      setEmailErr("Ce champ est obligatoire.")
      hasErr = true
    } else if (!regEmail.test(email)) {
      setEmailErr("Prénom non valide.")
      hasErr = true
    }
    if (!message) {
      setMessageErr("Ce champ est obligatoire.")
      hasErr = true
    }
    if (hasErr) {
      setHasErr(true)
    } else {
      setHasErr(false)
      setPrenomErr("")
      setNomErr("")
      setEmailErr("")
      setMessageErr("")
      // TODO: envoyer form
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="text-xl sm:text-2xl text-gray-900 font-semibold text-center">
        Votre question ou remarque générale
      </div>
      <div className="sm:px-4 py-6 mt-2 text-gray-800">
        L’identité sert à identifier l’émetteur de la requête et ne sera jamais partagée auprès de tiers.
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 sm:gap-x-16">
        <Input label="Prénom" value={prenom} onInputValueChanged={handlePrenomChanged} error={prenomErr}></Input>
        <Input label="Nom" value={nom} onInputValueChanged={handleNomChanged} error={nomErr}></Input>
        <div className="sm:col-span-2">
          <Input
            label="E-mail professionel"
            value={email}
            type="email"
            onInputValueChanged={handleEmailChanged}
            error={emailErr}
            stretch={true}
          ></Input>
        </div>
        <div className="sm:col-span-2">
          <TextArea
            label="Message"
            value={message}
            onInputValueChanged={handleMessageChanged}
            error={messageErr}
            stretch={true}
          ></TextArea>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="mt-12 w-full sm:w-48">
          <Button color="green" size="full-mobile" submit={true}>
            Envoyer
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
