import React, { MouseEvent } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

type ContactCardProps = {
  image: string
  title: string
  moreInfo: string
  link: { text: string; to?: string }
  onContactCardClick?: () => void
}

const ContactCard = (props: ContactCardProps) => {
  const getImage = (image: string) => {
    switch (image) {
      case "formulaire-contact":
        return (
          <StaticImage
            style={{
              maxHeight: 80,
              maxWidth: 80,
            }}
            alt=""
            loading="eager"
            src="../images/formulaire-contact.png"
            formats={["auto", "webp"]}
            quality={90}
          />
        )
      case "voyage":
        return (
          <StaticImage
            style={{
              maxHeight: 80,
              maxWidth: 80,
            }}
            alt=""
            loading="eager"
            src="../images/voyage.png"
            formats={["auto", "webp"]}
            quality={90}
          />
        )
      case "entraide":
        return (
          <StaticImage
            style={{
              maxHeight: 80,
              maxWidth: 80,
            }}
            alt=""
            loading="eager"
            src="../images/entraide.png"
            formats={["auto", "webp"]}
            quality={90}
          />
        )
      case "fleur":
        return (
          <StaticImage
            style={{
              maxHeight: 80,
              maxWidth: 80,
            }}
            alt=""
            loading="eager"
            src="../images/fleur.png"
            formats={["auto", "webp"]}
            quality={90}
          />
        )

      default:
        return <></>
    }
  }
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (props.onContactCardClick) {
      props.onContactCardClick()
    }
  }
  return (
    <div className="w-72 h-80 pt-2 pb-6 px-6 border-2 border-blue-500">
      <div className="h-full flex flex-col text-center">
        <div className="mx-auto ">{getImage(props.image)}</div>
        <div className="mt-2 text-gray-900 font-semibold">{props.title}</div>
        <div className="my-auto text-sm text-blue-500">{props.moreInfo}</div>
        <div onClick={handleClick} className="mt-auto py-2 px-4 bg-blue-500 text-white cursor-pointer">
          <Link to={`${props.link.to}`}>{props.link.text}</Link>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
