import React, { ChangeEvent } from "react"

type TextAreaProps = {
  label: string
  value: string
  onInputValueChanged: (value: string) => void
  stretch?: boolean
  error?: string
}

const TextArea = (props: TextAreaProps) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    props.onInputValueChanged(e.target.value)
  }
  const widthClasses = props.stretch ? "w-full" : "w-full md:w-48 lg:w-60 xl:w-64"
  return (
    <div>
      <div className="text-sm text-gray-700">{props.label}</div>
      <label
        className={`flex flex-row justify-between align-middle px-3 h-32 border-gray-600 bg-gray-100 hover:bg-gray-200 focus-within:border-gray-600 border-2 overflow-hidden ${widthClasses}`}
        htmlFor={props.label}
      >
        <textarea
          className="w-full outline-none py-1 bg-gray-100 hover:bg-gray-200 resize-none"
          id={props.label}
          value={props.value}
          onChange={handleChange}
        ></textarea>
      </label>
      {props.error ? <div className="text-xs italic text-red-500">{props.error}</div> : <></>}
    </div>
  )
}

export default TextArea
